const scrollTo = (ref, offset, toTop = false) => {
    return () => {
        //ref.current.scrollIntoView({ behavior: 'smooth' });
        window.scroll({
        top: toTop? 0 : ref.current.offsetTop + offset,
        //behavior: 'smooth'
        });
    };
};

export default scrollTo;
