import { useScroll, useTransform, motion } from 'motion/react';
import { useRef } from 'react';
import './About.css';

const About = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start']
  });

  const sloganFloorsStyle = {y: useTransform(scrollYProgress, [0, 1], [200, -150])};
  const sloganVisionStyle = {y: useTransform(scrollYProgress, [0, 1], [80, -60])};

  return (
    <div ref={container} className="About">
      <div className="About-sloganMore">More Than</div>
      <motion.div
        style={sloganFloorsStyle}
        className="About-sloganFloors"
      >Floors</motion.div>
      <motion.div
        style={sloganVisionStyle}
        className="About-sloganVision"
      >We Build Your Vision.</motion.div>
      <div className="About-descriptionContainer">
        <div className="About-description">
          <div className="About-descriptionText1">At Halls Flooring, we began with a focus on floors and carpeting, but our expertise has grown to cover all aspects of commercial renovations. As a full-service general contracting company, we deliver high-quality craftsmanship in flooring, walls, ceilings, and complete interior transformations. Whether it’s a sleek office space, a durable retail environment, or a full-scale commercial upgrade, we bring precision, efficiency, and expertise to every project. From the ground up, we build better.</div>
          <div className="about-descriptionText2">The Halls family has been providing unmatched craftsmanship to clients across Southwestern Ontario for over fifty years. Experience the difference half a century makes.</div>
        </div>
      </div>
    </div>
  );
};

export default About;
