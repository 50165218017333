import { useState } from 'react';
import overlay from './overlay.png';
import data from './Testimonials.json';
import { ReactComponent as Star } from './star.svg';
import splitStringUsingRegex from '../../utils/splitStringUsingRegex.tsx';
import { motion, AnimatePresence } from 'motion/react';
import './Testimonials.css';

const Testimonials = () => {
    const overlayStyle = {
        backgroundImage: 'url(' + overlay + ')'
    }

    data.forEach((dataItem) => {
        dataItem.authorChars = splitStringUsingRegex(dataItem.author);
        dataItem.textChars = splitStringUsingRegex(dataItem.text);
    });

    const charVariants = {
        hidden: { opacity: 0 },
        reveal: { opacity: 1 }
    };

    const [testimonialIndex, setTestimonialIndex] = useState(0);
    const [parentDelay, setParentDelay] = useState(0);

    const onAnimationComplete = () => {
        setTimeout(()=>{
            setTestimonialIndex((testimonialIndex + 1) % data.length);
            if (!parentDelay) setParentDelay(0.4);
        }, 4000);
    }

    return (
        <div className="Testimonials h-[30rem] sm:h-[28rem] md:h-[26rem] lg:h-[24rem]" style={overlayStyle}>
            <AnimatePresence>
                <motion.div
                    className="Testimonials-card w-[20rem] sm:w-[30rem] md:w-[38rem] lg:w-[42rem]"
                    key={'testimonial-' + testimonialIndex}
                    initial={{ opacity: 0}}
                    whileInView={{ opacity: 1 }}
                    exit={{
                        opacity: 0,
                        transition: { duration: 0.5, delay: 0 }
                    }}
                    transition={{ duration: 0.5, delay: parentDelay }}
                    viewport={{ once: true, amount: 0.5 }}
                >
                    <motion.div
                        onAnimationComplete={onAnimationComplete}
                        key={'testimonial-' + testimonialIndex}
                        className="Testimonials-text"
                        initial="hidden"
                        animate="reveal"
                        transition={{ staggerChildren: 0.01, delayChildren: 0.5 + parentDelay }}
                    >
                        {data[testimonialIndex].textChars.map((char, charIndex) => (
                            <motion.span
                                key={'testimonial-' + testimonialIndex + '-' + char + '-' + charIndex}
                                transition={{ duration: 0.5 }}
                                variants={charVariants}
                            >
                                {char === '\n' ? <br /> : char}
                            </motion.span>
                        ))}
                    </motion.div>
                    <div className="Testimonials-stars">
                        <Star className="Testimonials-star Testimonials-space"/>
                        <Star className="Testimonials-star Testimonials-space"/>
                        <Star className="Testimonials-star Testimonials-space"/>
                        <Star className="Testimonials-star Testimonials-space"/>
                        <Star className="Testimonials-star"/>
                    </div>
                    <div className="Testimonials-author">{data[testimonialIndex].author}</div>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

export default Testimonials;
