import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="Footer-text">
                Halls Flooring © {new Date().getFullYear()}
            </div>
        </div>
    );
};

export default Footer;
