import React, { useRef } from 'react';
import { animate, KeyframeOptions, useInView, useIsomorphicLayoutEffect } from 'motion/react';
import getDecimalPlaces from '../../utils/getDecimalPlaces.tsx';

// last part of this video makes use of reduce animation on system settings (windows).. ffs
// https://www.youtube.com/watch?v=Q78i8TRIxMk

type AnimatedCounterProps = {
    from: number;
    to: number;
    duration: number;
    animationOptions?: KeyframeOptions;
};

const AnimatedCounter = ({
    from,
    to,
    duration,
    animationOptions
}: AnimatedCounterProps) => {
    const ref = useRef<HTMLSpanElement>(null);
    const inView = useInView(ref, { once: true });

    const toFixed = getDecimalPlaces(to);

    useIsomorphicLayoutEffect(()=>{
        const element = ref.current;

        if (!element) return;
        if (!inView) return;

        element.textContent = String(from);

        const controls = animate(from, to, {
            duration,
            ease: "easeOut",
            ...animationOptions,
            onUpdate(value) {
                element.textContent = value.toFixed(toFixed);
            }
        });

        return () => {
            controls.stop();
        }
    }, [ref, inView, from, to])

    return <span ref={ref} />;
};

export default AnimatedCounter;
