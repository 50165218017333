import { useState } from 'react';
import contactDetails from '../../constants/contact-detail.json';
import ThreeDotsWave from '../ThreeDotWave/ThreeDotWave';
import validateEmail from '../../utils/validateEmail.js';
import endpoints from '../../constants/endpoints.json';
import './Contact.css';

const Contact = () => {
    const endpoint = endpoints[process.env?.NODE_ENV]['contact-url'] +
        endpoints[process.env?.NODE_ENV]['contact-path'];
    const [isSending, setIsSending] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [sentSuccess, setSentSuccess] = useState(false);

    const closeModal = () => {
        if (sentSuccess) {
            setSentSuccess(false);
        }
        if (hasError) {
            setHasError(false);
        }
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [feedbackName, setFeedbackName] = useState('');
    const [feedbackEmail, setFeedbackEmail] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const handleNameChange = (event) => {
        if (feedbackName) {
            setFeedbackName('');
        }
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        if (feedbackEmail) {
            setFeedbackEmail('');
        }
        setEmail(event.target.value);
    };
    const handleMessageChange = (event) => {
        if (feedbackMessage) {
            setFeedbackMessage('');
        }
        setMessage(event.target.value);
    };

    const sendData = () => {
        if (!name) {
            setFeedbackName('Please provide your name');
            return;
        }
        if (!email) {
            setFeedbackEmail('Please provide your email');
            return;
        }
        if (!validateEmail(email)) {
            setFeedbackEmail('Please provide a valid email');
            return;
        }
        if (!message) {
            setFeedbackMessage('Please provide a message');
            return;
        }

        setIsSending(true);

        fetch(endpoint, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name,
              email,
              message
            })
          })
          .then((response) => {
            if (response.status >= 500) {
                throw new Error('500');
            }

            setSentSuccess(true);
            setIsSending(false);
            setName('');
            setEmail('');
            setMessage('');
          })
          .catch((err) => {
            setIsSending(false);
            setHasError(true);
          });
    };

    return (
        <div className="Contact">


            {(hasError || sentSuccess) &&
            <div id="default-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center content-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-80"></div>
                  <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white border border-gray-400 rounded-lg shadow-sm dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 border-gray-200">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {sentSuccess ? "Message Sent" : "Oops"}
                            </h3>
                            <button onClick={closeModal} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                {sentSuccess ? "We have received your message! Please allow for 1-2 business days to hear back from us." : "There seems to be an issue with the contact form. We will look into this and resolve the issue as soon as possible."}
                            </p>
                            {hasError &&
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                In the meantime, feel free to give is a call at <span style={{fontWeight: 600}}><a href={'tel:' + contactDetails.phone}>{contactDetails.phone}</a></span> or send us an email at <span style={{fontWeight: 600}}><a href={'mailto:' + contactDetails.email}>{contactDetails.email}</a></span>. We apologize for the inconvenience.
                            </p>}
                        </div>
                        <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={closeModal} data-modal-hide="default-modal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            }



            <div className="Contact-cardContainer">
                <div className="Contact-card rounded-xl">
                    <div className="Contact-col1">
                        <div className="Contact-title">Get in Touch</div>
                        <div className="Contact-desc">
                            <div className="balls"></div>
                            <div className="Contact-desc1">Got a project in mind? Let's make it a reality.</div>
                            <div className="Contact-desc2">Use the form, call or email us for a free quote and we will respond right away.</div>
                        </div>
                        <div className="Contact-col1Space"></div>
                        <div className="Contact-details">
                            <div className="Contact-el">
                                <div className="Contact-lbl">
                                    Phone
                                </div>
                                <div className="Contact-val">
                                    <a href={'tel:' + contactDetails.phone}>{contactDetails.phone}</a>
                                </div>
                            </div>
                            <div className="Contact-el">
                                <div className="Contact-lbl">
                                    Email
                                </div>
                                <div className="Contact-val">
{/*                                <div className="Phone">
              <div className="Icon"><FaPhone /></div>
              <div>{phone}</div>
            </div>
          </a>
          <a href={emailHref}>
            <div className="Email">
              <div className="Icon"><FaEnvelope /></div>
              <div>{email}</div>
            </div>
          </a>
*/}
                                    <a href={'mailto:' + contactDetails.email}>{contactDetails.email}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Contact-col2">
                        <form className="bg-white rounded">
                            <div className="mb-4">
                                <div className="block w-full flex justify-between">
                                    <label
                                        className="text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="contact-name"
                                    >
                                        Name
                                    </label>
                                    <span className="text-red-700 text-sm font-bold mb-2">{feedbackName}</span>
                                </div>
                                <input
                                    className="Contact-formField shadow bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-red dark:focus:ring-red-500 dark:focus:border-red-500"
                                    id="contact-name"
                                    type="text"
                                    value={name}
                                    onChange={handleNameChange}
                                    placeholder="Name"
                                />
                            </div>
                            <div className="mb-4">
                                <div className="block w-full flex justify-between">
                                    <label
                                        className="text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="contact-email"
                                    >
                                        Email
                                    </label>
                                    <span className="text-red-700 text-sm font-bold mb-2">{feedbackEmail}</span>
                                </div>
                                <input
                                    className="Contact-formField shadow bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-red dark:focus:ring-red-500 dark:focus:border-red-500"
                                    id="contact-email"
                                    type="text"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="Email"
                                />
                            </div>
                            <div className="mb-4">
                                <div className="block w-full flex justify-between">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="contact-message"
                                    >
                                        Message
                                    </label>
                                    <span className="text-red-700 text-sm font-bold mb-2">{feedbackMessage}</span>
                                </div>

                                <textarea
                                    className="Contact-textArea shadow bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-white-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-red dark:focus:ring-red-500 dark:focus:border-red-500"
                                    id="contact-message"
                                    value={message}
                                    onChange={handleMessageChange}
                                    type="text"
                                    placeholder="Message"
                                />
                            </div>
                            {/*
                            consider adding bottom border only for 3d effect for this button:
                            border-b-4*/}
                            <button
                                onClick={sendData}
                                type="button"
                                disabled={isSending}
                                className={(isSending ? "opacity-50 cursor " : "") + "h-[2.2rem] text-red-900 bg-white border border-red-300 focus:outline-none hover:bg-red-100 focus:ring-4 focus:ring-red-100 w-full font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-red-800 dark:text-white dark:border-red-600 dark:hover:bg-red-700 dark:hover:border-red-600 dark:focus:ring-red-700"}
                            >{isSending ? <ThreeDotsWave /> : "Submit"}</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="Contact-whitespace">
                <iframe
                    className="Contact-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2921.7216122669315!2d-81.26402102308138!3d42.92091187114641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ef14dbd0182f1%3A0x86cb785b8106a60a!2sHalls%20Flooring%20Services!5e0!3m2!1sen!2sca!4v1742247609072!5m2!1sen!2sca"
                    title="googlemap"
                    width="100%"
                    height="100%"
                    allowFullScreen=""
                    allow="fullscreen"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};

export default Contact;
