import { motion } from 'motion/react';
import bluestoneLogo from './bluestone.avif';
import briarlaneLogo from './briarlane.avif';
import clvLogo from './clv.avif';
import hazelviewLogo from './hazelview.avif';
import siftonLogo from './sifton.avif';
import summitLogo from './summit.avif';
import './Partners.css';

const Partners = () => {
  return (
    <div className="Partners">
      <motion.div
        className="Partners-title"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: {
          delay: 0.2, duration: 0.5
        }}}
        viewport={{ once: true, amount: 1.0 }}
      >Strong Partners.</motion.div>
      <div className="Partners-logoRow">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [-50, 0],
            transition: {
              delay: 0.6,
              duration: 1.0
            }
          }}
          viewport={{ once: true, amount: 0.8 }}>
          <img src={bluestoneLogo} className="Partners-logo1" alt="bluestone" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [-125, 0],
            transition: {
              delay: 0.4,
              duration: 1.0
            }
          }}
          viewport={{ once: true, amount: 0.8 }}>
          <img src={summitLogo} className="Partners-logo2" alt="summit" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [-200, 0],
            transition: {
              delay: 0.2,
              duration: 1.0
            }
          }}
          viewport={{ once: true, amount: 0.8 }}>
          <img src={briarlaneLogo} className="Partners-logo3" alt="briarlane" />
        </motion.div>
      </div>
      <div className="Partners-logoRow">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [200, 0],
            transition: {
              delay: 0.4,
              duration: 1.0
            }
          }}
          viewport={{ once: true, amount: 0.8 }}>
          <img src={clvLogo} className="Partners-logo4" alt="clv" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [125, 0],
            transition: {
              delay: 0.6,
              duration: 1.0
            }
          }}
          viewport={{ once: true, amount: 0.8 }}>
          <img src={hazelviewLogo} className="Partners-logo5" alt="hazelview" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            x: [50, 0],
            transition: {
              delay: 0.8,
              duration: 1.0
            }
          }}
          viewport={{ once: true, amount: 0.8 }}>
          <img src={siftonLogo} className="Partners-logo6" alt="sifton" />
        </motion.div>
      </div>
      <motion.div
        className="Partners-footer"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: {
          delay: 0.2, duration: 0.5
        }}}
        viewport={{ once: true, amount: 1.0 }}
      >Strong Projects.</motion.div>
    </div>
  );
};

export default Partners;
