import { useRef, useEffect } from 'react';
import { useInView, useAnimate, motion } from 'framer-motion';
import scrollTo from './utils/scrollTo.js';
import bgVid from './bg-vid.mp4';
import bgContact from './bg-contact.jpg';
import NavBar from './components/NavBar/NavBar.js';
import Header from './components/Header/Header.js';
import About from './components/About/About.js';
import Partners from './components/Partners/Partners.js';
import Testimonials from './components/Testimonials/Testimonials.js';
import Services from './components/Services/Services.js';
import Contact from './components/Contact/Contact.js';
import Footer from './components/Footer/Footer.js';
import './App.css';

const App = () => {
  // Hide NavBar
  const hideNavBarRef = useRef();
  const isHeaderInView = useInView(hideNavBarRef, { once: false });

  // Home
  const onHomeClick = scrollTo(null, 0, true);

  // About
  const aboutRef = useRef();
  const onAboutClick = scrollTo(aboutRef, -200, false);

  // Partners
  const partnersRef = useRef();
  const onPartnersClick = scrollTo(partnersRef, -100, false);

  // Testimonials
  const testimonialsRef = useRef();
  const onTestimonialsClick = scrollTo(testimonialsRef, -100, false);

  // Services
  const servicesRef = useRef();
  const onServicesClick = scrollTo(servicesRef, -100, false);

  // Contact
  const bgContactId = 'bg-contact';
  const [contactRef, bgContactAnimate] = useAnimate();
  const isContactInView = useInView(contactRef, { once: false, amount: "some" });

  const onContactClick = scrollTo(contactRef, -100, false);

  useEffect(() => {
    bgContactAnimate('#' + bgContactId, {
      opacity: (isContactInView ? [0, 1] : [1, 0])
    }, {
      duration: 0.01,
      ease: "linear"
    });
  }, [isContactInView, bgContactAnimate, bgContactId]);

  return (
    <div className="App">
      <div className="App-hideNavBar" ref={hideNavBarRef}></div>
      <video
        id="pageBackground_a1eku_video"
        className="App-bgVid"
        crossOrigin="anonymous"
        playsInline
        preload="auto"
        muted
        loop
        tabIndex="-1"
        autoPlay
        src={bgVid}></video>
      <NavBar
        hidden={isHeaderInView}
        onHomeClick={onHomeClick}
        onAboutClick={onAboutClick}
        onPartnersClick={onPartnersClick}
        onTestimonialsClick={onTestimonialsClick}
        onServicesClick={onServicesClick}
        onContactClick={onContactClick}
      />
      <div className="App-refContainer">
        <Header onContactClick={onContactClick} />
      </div>
      <div className="App-refContainer" ref={aboutRef}>
        <About />
      </div>
      <div style={{height: "200px"}}></div>
      <div className="App-refContainer" ref={partnersRef}>
        <Partners />
      </div>
      <div className="App-refContainer" ref={testimonialsRef}>
        <Testimonials />
      </div>
      <div className="App-refContainer" ref={servicesRef}>
        <Services onContactClick={onContactClick} />
      </div>
      <div className="App-refContainer" ref={contactRef}>
        <motion.div
          id={bgContactId}
          className="App-bgContact"
          style={{backgroundImage: 'url(' + bgContact + ')'}}
        ></motion.div>
        <Contact />
      </div>
      <div className="App-refContainer">
        <Footer />
      </div>
    </div>
  );
};

export default App;
