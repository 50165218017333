import hallsLogo from './halls-logo.png.avif';
//import { /*FaCompass, */FaEnvelope, FaPhone } from "react-icons/fa";
//import overlay from './overlay.png';
//import contactDetails from '../../constants/contact-detail.json';
import Stats from '../Stats/Stats';
import './Header.css';

const Header = ({
  onContactClick
}) => {
  // Figure out a way to blend this with overlay
  // Or use pure css to plaid it up.
  /*const overlayStyle = {
    backgroundImage: 'url(' + overlay + ')'
  }
  <div style={overlayStyle} className="Header-overlayGradient"></div>*/

  return (
    <div className="Header">
      <div className="Header-overlay">
        <img className="Header-logo w-[20rem] sm:w-[24rem] md:w-[28rem] lg:w-[36rem]" src={hallsLogo} alt="Company Logo" />
        <button
          type="button"
          className="Header-getInTouch text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600"
          onClick={onContactClick}
        >Get in touch</button>
        {/*<div className="Header-location">
          {
          //<div className="Header-icon"><FaCompass /></div>
          }
          <div>191A Exeter Rd, London, ON N6L 1A4</div>
        </div>
        <div className="Header-contactInfo">
          <a href={'tel:' + contactDetails.phone}>
            <div className="Header-phone">
              <div className="Header-icon"><FaPhone /></div>
              <div>{contactDetails.phone}</div>
            </div>
          </a>
          <a href={'mailto:' + contactDetails.email}>
            <div className="Header-email">
              <div className="Header-icon"><FaEnvelope /></div>
              <div>{contactDetails.email}</div>
            </div>
          </a>
        </div>*/}
        <Stats />
      </div>
      <div className="Header-overlayBottom"></div>
    </div>
  );
};

export default Header;
