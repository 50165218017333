import AnimatedCounter from '../AnimatedCounter/AnimatedCounter.tsx';
import './Stat.css';

const Stat = ({num, xtraChar, desc}) => {
  return (
    <div className="Stat">
      <div className="Stat-value text-3xl font-bold font-mono m-4">
        <AnimatedCounter from={0} to={num} duration={2.0} />{xtraChar ? xtraChar : ''}
      </div>
      <div className="Stat-label">{desc}</div>
    </div>
  );
};

export default Stat;
